import { ReactElement } from "react";
import { Stack, Typography } from "@mui/material";
import { Error } from "@mui/icons-material";

const EditError = (): ReactElement => (
  <Stack alignItems="center" sx={{ fontSize: 64 }}>
    <Error fontSize="inherit" color="error" sx={{ margin: 4 }} />
    <Typography variant="h5" gutterBottom>
      Something went wrong!
    </Typography>
    <Typography color="textSecondary">
      We couldn't process your submission.
    </Typography>
  </Stack>
);

export { EditError };
