import { ViewState } from "react-map-gl";
import { atom, atomFamily, selector } from "recoil";
import { initialViewState } from "./initial-view-state";

export enum EditState {
  Editing,
  Loading,
  Success,
  Error,
}

const editStateFamily = atomFamily({
  key: "EditState",
  default: EditState.Editing,
});

const mapViewState = atom({
  key: "MapViewState",
  default: initialViewState,
});

const mapViewStateSelector = selector<ViewState>({
  key: "MapViewStateSelector",
  get: ({ get }) => get(mapViewState),
  set: ({ set }, nextViewState) => set(mapViewState, nextViewState),
});

const editPanelOpenState = atom({
  key: "EditPanelOpenState",
  default: false,
});

export { editStateFamily, mapViewStateSelector, editPanelOpenState };
