import { Project } from "../types/project";
import { geohashPrecision, minPostfixIdLength } from "./config";
import { decode, encode } from "ngeohash";

const encodeLocation = ({ id, location: { lat, lng } }: Project): string =>
  `${encode(lat, lng, geohashPrecision)}${parseInt(id, 10).toString(16)}`;

const parseLocationString = (
  locationString: string
): Pick<Project, "id" | "location"> | null => {
  if (locationString.length < geohashPrecision + minPostfixIdLength) {
    return null;
  }

  const geohash = locationString.substring(0, geohashPrecision);
  const { latitude: lat, longitude: lng } = decode(geohash);

  const postfixId = locationString.substring(geohashPrecision);
  const id = parseInt(postfixId, 16).toString();

  return {
    id,
    location: {
      lat,
      lng,
    },
  };
};

export { encodeLocation, parseLocationString };
