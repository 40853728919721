import { lightBackground, nord } from "./global/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RecoilRoot } from "recoil";
import { Home } from "./components/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

declare global {
  interface Window {
    INITIAL_GEOHASH: string;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: nord[10],
    },
    secondary: {
      main: nord[9],
    },
    error: {
      main: nord[11],
    },
    text: {
      primary: nord[1],
      secondary: nord[3],
    },
    background: {
      paper: lightBackground,
    },
  },
  typography: {
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
  },
});

function App() {
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/location/:locationString" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
