enum Environment {
  Development = "DEVELOPMENT",
  Staging = "STAGING",
  Production = "PRODUCTION",
}

// TODO: Want build-time checks for these being set
const environment = ((): Environment => {
  const setEnv = process.env.REACT_APP_ENVIRONMENT;

  if (!setEnv) {
    throw new Error("Missing REACT_APP_ENVIRONMENT");
  }

  if (!Object.values(Environment).includes(setEnv as Environment)) {
    throw new Error("Invalid REACT_APP_ENVIRONMENT");
  }

  return setEnv as Environment;
})();

const mapboxToken = ((): string => {
  const setToken = process.env.REACT_APP_MAPBOX_TOKEN;

  if (!setToken) {
    throw new Error("Missing REACT_APP_MAPBOX_TOKEN");
  }

  return setToken;
})();

process.env.REACT_APP_MAPBOX_TOKEN as string;

const hostnames = {
  [Environment.Development]: "development.archmap.cc",
  [Environment.Staging]: "development.archmap.cc",
  [Environment.Production]: "archmap.cc",
};

const hostname = hostnames[environment];

const mapMarkerLayerId = "archmap";
const mapPopupMinZoom = 12;
const mapCursor = "auto";

const mapConfig = {
  reuseMaps: true,
  style: {
    width: "100vw",
    height: "100vh",
  },
  minZoom: 2,
  maxZoom: 16,
  minPitch: 0,
  maxPitch: 0,
  mapStyle: "mapbox://styles/archmap/ckyhhlem60m9s14nvwf8ph8dj",
  mapboxAccessToken: mapboxToken,
};

const geohashPrecision = 8;
const minPostfixIdLength = 1;

export {
  hostname,
  mapMarkerLayerId,
  mapPopupMinZoom,
  mapCursor,
  mapConfig,
  geohashPrecision,
  minPostfixIdLength,
};
