import {
  Button,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
  lighten,
  darken,
  LinearProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { ReactElement } from "react";
import { Project } from "../types/project";
import { EditState, editStateFamily } from "../global/atoms";
import { hostname } from "../global/config";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { ChevronRight } from "@mui/icons-material";
import { nord } from "../global/colors";
import { useRecoilState } from "recoil";
import { EditSuccess } from "./EditSuccess";
import { EditError } from "./EditError";
import isPropValid from "@emotion/is-prop-valid";

const contentPadding = 3;

const validationSchema = yup.object({
  link: yup
    .string()
    .max(50)
    .matches(/^https:\/\/goo.gl\/maps\/[a-zA-Z0-9]+$/)
    .required(),
  token: yup.string().required(),
});

interface FormValues extends yup.InferType<typeof validationSchema> {}

const FormWrapper = styled(Form)(({ theme }) => ({
  padding: theme.spacing(contentPadding),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

enum LinkStatus {
  Normal,
  Error,
}

const LinkInput = styled(Field, { shouldForwardProp: isPropValid })(
  ({ theme, linkStatus }: { theme: any; linkStatus: LinkStatus }) => {
    const outlineColor = {
      [LinkStatus.Normal]: darken(nord[4], 0.2),
      [LinkStatus.Error]: nord[11],
    };

    return {
      padding: theme.spacing(1),
      width: "100%",
      border: "none",
      borderRadius: theme.spacing(1),
      color: nord[3],
      outline: `2px solid ${outlineColor[linkStatus]}`,
      backgroundColor: nord[5],
      fontSize: theme.body1,
      "::placeholder": {
        color: darken(nord[4], 0.4),
      },
      ":focus": {
        outline: `2px solid ${nord[10]}`,
      },
    };
  }
);

// For some reason, can't style HCaptcha component directly
const CaptchaWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

interface EditPanelProps {
  project: Project;
  onClose: () => void;
}

const EditPanel = ({ project, onClose }: EditPanelProps): ReactElement => {
  const [state, setState] = useRecoilState(editStateFamily(project.id));

  const onSubmit = async (values: FormValues): Promise<void> => {
    setState(EditState.Loading);

    const response = await fetch(
      `https://${hostname}/api/verify/${project.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    );

    if (response.ok) {
      setState(EditState.Success);
    } else {
      setState(EditState.Error);
    }
  };

  return (
    <Stack sx={{ height: "100%" }}>
      <Typography variant="h4" sx={{ padding: contentPadding }}>
        Verify Location
      </Typography>
      <Divider />
      {state === EditState.Editing && (
        <>
          <Stack padding={contentPadding} spacing={2}>
            <Typography color="textSecondary">
              Provide a link to a known place on{" "}
              <Link
                variant="inherit"
                href={`https://www.google.com/maps/search/?api=1&query=${project.location.lat},${project.location.lng}`}
                target="_blank"
                rel="noreferrer"
              >
                Google Maps
              </Link>
              . This option works best for locations that are businesses or
              public spaces.
            </Typography>
            <div>
              <Divider variant="middle">
                <Typography color="textSecondary">OR</Typography>
              </Divider>
            </div>
            <Typography color="textSecondary">
              Provide a link to a{" "}
              <Link
                variant="inherit"
                href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${project.location.lat},${project.location.lng}`}
                target="_blank"
                rel="noreferrer"
              >
                Google Street View
              </Link>{" "}
              image. This option works best for locations that are offices or
              residential buildings.
            </Typography>
          </Stack>
          <Divider />
          <Typography color="textSecondary" sx={{ padding: contentPadding }}>
            <Link
              variant="inherit"
              href="https://support.google.com/maps/answer/144361"
              target="_blank"
              rel="noreferrer"
            >
              Google Maps Help
            </Link>{" "}
            explains how to create these links. For both options, the link
            format should match the placeholder below.
          </Typography>
          <Divider />
          <Formik
            initialValues={{ link: "", token: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, setFieldValue, handleSubmit }) => (
              <FormWrapper onSubmit={handleSubmit}>
                <LinkInput
                  name="link"
                  linkStatus={
                    touched.link && errors.link
                      ? LinkStatus.Error
                      : LinkStatus.Normal
                  }
                  placeholder="https://goo.gl/maps/zLxtwgpTTxDShrLXA"
                />
                <CaptchaWrapper>
                  <HCaptcha
                    sitekey="cd6ba8ff-3ecd-463a-a3af-68a84288043a"
                    onVerify={(token) => setFieldValue("token", token)}
                    onExpire={() => setFieldValue("token", "")}
                  />
                </CaptchaWrapper>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  disabled={
                    !touched.link ||
                    Boolean(errors.link) ||
                    Boolean(errors.token)
                  }
                >
                  Submit
                </Button>
              </FormWrapper>
            )}
          </Formik>
        </>
      )}
      {state === EditState.Loading && (
        <Stack padding={contentPadding} spacing={2}>
          <LinearProgress />
          <LinearProgress />
          <LinearProgress />
          <span></span>
          <LinearProgress />
          <LinearProgress />
          <LinearProgress />
          <span></span>
          <LinearProgress />
          <LinearProgress />
          <LinearProgress />
        </Stack>
      )}
      {state === EditState.Success && <EditSuccess />}
      {state === EditState.Error && <EditError />}
      <IconButton
        onClick={onClose}
        sx={{
          color: lighten(nord[3], 0.2),
          margin: 1,
          marginTop: "auto",
          alignSelf: "start",
        }}
      >
        <ChevronRight fontSize="large" />
      </IconButton>
    </Stack>
  );
};

export default EditPanel;
