import { ReactElement } from "react";
import { Stack, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const EditSuccess = (): ReactElement => (
  <Stack alignItems="center" sx={{ fontSize: 64 }}>
    <CheckCircle fontSize="inherit" color="secondary" sx={{ margin: 4 }} />
    <Typography variant="h5" gutterBottom>
      Thanks for contributing!
    </Typography>
    <Typography color="textSecondary">
      Your submission will be reviewed soon.
    </Typography>
  </Stack>
);

export { EditSuccess };
