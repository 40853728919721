import { decode } from "ngeohash";
import { ViewState } from "react-map-gl";
import { parseLocationString } from "./location";

const locationSeed = (): { latitude: number; longitude: number } => {
  if (typeof window !== "undefined") {
    const matches = window.location.pathname.match(/^\/location\/([a-z0-9]+)$/);

    // If a location url is loaded directly, center map on that location
    if (matches && matches.length === 2) {
      const locationInfo = parseLocationString(matches[1]);

      if (locationInfo) {
        const {
          location: { lat: latitude, lng: longitude },
        } = locationInfo;
        return { latitude, longitude };
      }
    }

    if (window.INITIAL_GEOHASH) {
      // Geohash injected dynamically by worker based on user location
      return decode(window.INITIAL_GEOHASH);
    }
  }

  // Fallback to New York City
  return { latitude: 40.73071357829943, longitude: -73.98724831553557 };
};

const initialViewState: ViewState = {
  ...locationSeed(),
  zoom: 12,
  bearing: 0,
  pitch: 0,
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

export { initialViewState };
