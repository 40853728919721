import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import {
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Map,
  ViewStateChangeEvent,
  MapLayerMouseEvent,
  MapRef,
  ViewState,
} from "react-map-gl";
import { LngLat, LngLatLike, PointLike } from "mapbox-gl";
import MapPopup from "../components/MapPopup";
import { Project } from "../types/project";
import EditPanel from "../components/EditPanel";
import {
  mapMarkerLayerId,
  mapPopupMinZoom,
  mapCursor,
  mapConfig,
} from "../global/config";
import { encodeLocation, parseLocationString } from "../global/location";
import { useRecoilState } from "recoil";
import { editPanelOpenState, mapViewStateSelector } from "../global/atoms";
import { useLocation, useNavigate, useParams } from "react-router-dom";

type FlyingMapRef = MapRef & { isFlying: boolean };
type QueryRenderedFeatures = MapRef["queryRenderedFeatures"];

interface RawLocation extends Omit<Project, "architects" | "location"> {
  architects: string;
  location: string;
}

const parseLocation = (properties: RawLocation): Project =>
  ({
    ...properties,
    architects: JSON.parse(properties.architects),
    location: JSON.parse(properties.location),
  } as Project);

const closeEnough = (first: LngLatLike, second: LngLatLike): boolean =>
  LngLat.convert(first).distanceTo(LngLat.convert(second)) < 100;

const locationVisible = (map: MapRef, lngLat: LngLatLike): boolean =>
  map.getZoom() >= mapPopupMinZoom && map.getBounds().contains(lngLat);

// Check that marker layer exists (has loaded) before making query
const safeQueryRenderedFeatures = (
  ...[map, bound, options]: [MapRef, ...Parameters<QueryRenderedFeatures>]
): ReturnType<QueryRenderedFeatures> => {
  if (!map.getLayer(mapMarkerLayerId)) {
    return [];
  }

  return map.queryRenderedFeatures(bound, options);
};

const Home = (): ReactElement => {
  const [viewState, setViewState] = useRecoilState(mapViewStateSelector);
  const [editing, setEditing] = useRecoilState(editPanelOpenState);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [cursor, setCursor] = useState<string>(mapCursor);
  const mapRef = useRef<FlyingMapRef>();

  const navigate = useNavigate();
  const { locationString } = useParams();
  const { state } = useLocation() as { state?: { location: Project } };

  const selectedLocation = state?.location;
  const canRenderLocation = viewState.zoom >= mapPopupMinZoom;

  const flyTo = useCallback(
    (map: FlyingMapRef, location: Project): void => {
      // Callback when transition finishes
      map.once("moveend", (event) => {
        map.isFlying = false;
        const endViewState = event?.viewState as ViewState | undefined;

        if (
          !endViewState ||
          !closeEnough(location.location, [
            endViewState.longitude,
            endViewState.latitude,
          ]) ||
          endViewState.zoom !== mapPopupMinZoom
        ) {
          // Transition was interrupted
          navigate("/");
        }
      });

      // Start transition
      map.flyTo({
        zoom: mapPopupMinZoom,
        center: location.location,
        speed: 3,
      });

      map.isFlying = true;
    },
    [navigate]
  );

  // If application is loaded with direct link to location, open popup for that
  // location. If direct link is used, map will initially be centered at that
  // location, so full location object can be found by querying rendered
  // features.

  // Because (browser) location state is stored in history stack, initial load
  // should be the only situation where there is path parameter but no
  // associated state.
  const onLoad = useCallback((): void => {
    const map = mapRef.current;

    if (map && locationString && !selectedLocation) {
      const locationInfo = parseLocationString(locationString);

      if (locationInfo && locationVisible(map, locationInfo.location)) {
        // Hook will run again after layer loads
        if (!map.getLayer(mapMarkerLayerId)) {
          return;
        }

        const features = map.queryRenderedFeatures(undefined, {
          layers: [mapMarkerLayerId],
        });

        const locationFeature = features.find(
          ({ properties }) => properties?.id === locationInfo.id
        );

        if (locationFeature?.properties) {
          const location = parseLocation(
            locationFeature.properties as RawLocation
          );

          navigate(`/location/${encodeLocation(location)}`, {
            state: { location },
            replace: true,
          });

          return;
        }
      }

      // Path parameter was invalid or location not found
      navigate("/", { replace: true });
    }
  }, [locationString, selectedLocation, navigate]);

  // Zoom to selected location when it's off screen or the map is zoomed out
  // Important: Does not depend on view state, triggered by navigation
  useEffect(() => {
    const map = mapRef.current;

    if (
      map &&
      !map.isFlying &&
      selectedLocation &&
      !locationVisible(map, selectedLocation.location)
    ) {
      flyTo(map, selectedLocation);
    }
  }, [selectedLocation, flyTo]);

  // Clear selected location when it's off screen or the map is zoomed out
  // Important: Depends on view state, and runs after zoom hook above
  useEffect(() => {
    const map = mapRef.current;

    if (map && locationString && !map.isFlying) {
      const locationInfo = parseLocationString(locationString);

      if (!locationInfo || !locationVisible(map, locationInfo.location)) {
        navigate("/");
      }
    }
  }, [locationString, navigate, viewState]);

  // Close drawer when selected location is cleared
  useEffect(() => {
    if (!locationString) {
      setEditing(false);
    }
  }, [locationString, setEditing]);

  const onMove = useCallback(
    ({ viewState }: ViewStateChangeEvent): void => setViewState(viewState),
    [setViewState]
  );

  const onClick = useCallback(
    ({ point }: MapLayerMouseEvent): void => {
      const map = mapRef.current;

      if (map) {
        const region = ((): PointLike | [PointLike, PointLike] => {
          if (!smallScreen) {
            return point;
          }

          const { x, y } = point;
          const boxRadius = 8;

          return [
            [x - boxRadius, y - boxRadius],
            [x + boxRadius, y + boxRadius],
          ];
        })();

        const features = safeQueryRenderedFeatures(map, region, {
          layers: [mapMarkerLayerId],
        });

        if (features.length > 0) {
          const location = parseLocation(features[0].properties as RawLocation);

          navigate(`/location/${encodeLocation(location)}`, {
            state: { location },
          });
        } else if (locationString) {
          navigate("/");
        }
      }
    },
    [smallScreen, navigate, locationString]
  );

  const onMouseMove = useCallback(
    ({ point }: MapLayerMouseEvent): void => {
      const map = mapRef.current;

      if (map) {
        const features = safeQueryRenderedFeatures(map, point, {
          layers: [mapMarkerLayerId],
        });

        if (features.length > 0) {
          setCursor("pointer");
        } else {
          setCursor(mapCursor);
        }
      }
    },
    [setCursor]
  );

  const popup = useMemo(
    () =>
      selectedLocation && (
        <MapPopup project={selectedLocation} onEdit={() => setEditing(true)} />
      ),
    [selectedLocation, setEditing]
  );

  const drawer = useMemo(
    () => (
      <Drawer
        variant="persistent"
        anchor="right"
        open={editing && Boolean(selectedLocation)}
        PaperProps={{
          variant: "elevation",
          elevation: 3,
          sx: { width: 400, border: "none" },
        }}
      >
        {selectedLocation && (
          <EditPanel
            project={selectedLocation}
            onClose={() => setEditing(false)}
          />
        )}
      </Drawer>
    ),
    [editing, selectedLocation, setEditing]
  );

  return (
    <>
      <Map
        ref={mapRef as Ref<FlyingMapRef>}
        {...viewState}
        cursor={cursor}
        onLoad={onLoad}
        onMove={onMove}
        onClick={onClick}
        onMouseMove={onMouseMove}
        {...mapConfig}
      >
        {canRenderLocation && popup}
      </Map>
      {drawer}
    </>
  );
};

export { Home };
