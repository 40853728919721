import { ReactElement } from "react";
import { Marker } from "react-map-gl";
import { Project } from "../types/project";
import { Card } from "@mui/material";
import ProjectDetails from "./ProjectDetails";
// import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";

interface MapPopupProps {
  project: Project;
  onEdit: (project: Project) => void;
}

const MapPopup = ({ project, onEdit }: MapPopupProps): ReactElement => (
  <Marker
    latitude={project.location.lat}
    longitude={project.location.lng}
    anchor="top-left"
  >
    {/* <Badge
        badgeContent={
          <VerifiedRoundedIcon fontSize="large" sx={{ color: "#81a1c1" }} />
        }
      > */}
    <Card
      onClick={(event) => event.stopPropagation()}
      sx={{ minWidth: 200, maxWidth: 300, cursor: "auto" }}
    >
      <ProjectDetails project={project} onEdit={onEdit} />
    </Card>
    {/* </Badge> */}
  </Marker>
);

export default MapPopup;
