import {
  Button,
  CardActions,
  CardContent,
  lighten,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import { nord } from "../global/colors";
import { Project } from "../types/project";

const formatArchitects = (architects: string[]): [string, string] => {
  if (architects.length === 0) {
    return ["No Architects", ""];
  }

  if (architects.length === 1) {
    return [architects[0], ""];
  }

  return [architects[0], ` + ${architects.length - 1} More`];
};

interface ProjectDetailsProps {
  project: Project;
  onEdit: (project: Project) => void;
}

const ProjectDetails = ({
  project,
  onEdit,
}: ProjectDetailsProps): ReactElement => {
  const [first, rest] = formatArchitects(project.architects);

  return (
    <>
      <CardContent sx={{ paddingBottom: 0 }}>
        <Typography variant="h5" gutterBottom sx={{ lineHeight: 1.2 }}>
          {project.title}
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ fontWeight: 500, lineHeight: 1.2 }}
        >
          {first}
          {rest && (
            <Typography
              component="span"
              color={lighten(nord[3], 0.2)}
              sx={{ fontWeight: "inherit", lineHeight: "inherit" }}
            >
              {rest}
            </Typography>
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <a
          href={`https://www.archdaily.com/${project.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button>Article</Button>
        </a>
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${project.location.lat},${project.location.lng}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button>Directions</Button>
        </a>
        {/* <Button onClick={() => onEdit(project)}>Verify</Button> */}
      </CardActions>
    </>
  );
};

export default ProjectDetails;
